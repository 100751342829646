import React, { Component } from 'react'
import styled from 'styled-components'

class Modal extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }


  render() {
    let open = this.props.open
    return(
      <>
      {open &&
        <ModalWrapper>
          <button
          onClick={this.props.handleClose}
          className='wrapper-close'>
          </button>
          <ModalContent>
            <button
              onClick={this.props.handleClose}
              className='modal-close'>
            x</button>
            <div className='modal-inner'>
              {this.props.children}
            </div>
          </ModalContent>
        </ModalWrapper>
      }
      </>
    )
  }
}

export default(Modal)

const ModalWrapper = styled.section`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0,0,0,0.6);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 1001;
.wrapper-close {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
`

const ModalContent = styled.section`
width: 90vw;
height: calc(100vh - 2rem);
max-width: 350px;
max-height: 600px;
position: relative;
background-color: #fff;
border-radius: 10px;
padding: 2rem 1rem 1rem;
.modal-close {
  position:absolute;
  color: #545454;
  top: .5rem;
  right: .5rem;
  font-weight: bold;
  font-size: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: .75rem;
  border: solid 2px #545454;
  padding: 0;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.modal-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
}
.carousel-root {
  margin-top: .25rem;
}
.content-text {
  flex-grow: 1;
}
.content-title *{
  display: inline-block;
}
h1 {
  text-transform: uppercase;
  margin: 0;
  font-size: .9rem;
}
h1.year {
  font-weight: 100;
  margin: 0;
}
a.button {
  display: block;
  width: calc(100% - 2rem);
  color: #fff;
  background-color: #E21C3C;
  text-align: center;
  border-radius: 5px;
  border: none;
  text-decoration: underline;
  padding: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.carousel .slide img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  aspect-ratio: 6 / 4;
}
`
