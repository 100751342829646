import React, { Component } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import styled from 'styled-components'
import '../css/body.css'
import 'leaflet/dist/leaflet.css'
import Markers from '../../data/markers.json'
import L from 'leaflet'
import HeroCover from '../components/hero-cover'
import Modal from '../components/modal'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import arrowLeftImg from '../images/arrow-left.svg'
import favicon from '../images/favicon.png'
const isBrowser = typeof window !== `undefined`

//mapbox://styles/mwieczorkowski/cla6xeovq002a14n22bnho004

const lat = 37.784172
const long = -122.401558
const CENTER = [lat,long]
const mapSettings = {
  defaultBaseMap: 'Mapbox',
  zoom: 14,
  center: CENTER,
  height: '100vh'
};

class IndexPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popOpen: true,
      markers: Markers,
      showHero: true,
      showGuideText: true
    }
  }

  showMarkerDetail = (index) => {
    let markers = this.state.markers
    markers.forEach((marker, i) => {
      marker.show = false
    })
    markers[index].show = true
    this.setState({
      markers: markers
    })
  }

  closeMarker = () => {
    let markers = this.state.markers
    markers.forEach((marker, i) => {
      marker.show = false
    })
    this.setState({
      markers: markers,
      showGuideText: false
    })
  }

  toggleHero = () => {
    this.setState({
      showHero: !this.state.showHero
    })
  }

  render() {
    let RedIcon = null
    let BlueIcon = null
    if(isBrowser){
      RedIcon = L.icon({
            iconUrl: "/marker-icon-red.png",
            iconSize: [25, 41],
            iconAnchor: [12, 12],
            popupAnchor: [0, 0],
        })

      BlueIcon = L.icon({
            iconUrl: "/marker-icon.png",
            iconSize: [25, 41],
            iconAnchor: [12, 12],
            popupAnchor: [0, 0],
        })
    }
    return (
      <MainComponent>
        {this.state.showHero &&
          <HeroCover
          toggleHero={this.toggleHero}
          open={this.state.showHero}
          />
        }
        {!this.state.showHero &&
          <div className='guide-top'>
          <button className='back-button' onClick={this.toggleHero}>
          <img src={arrowLeftImg} />
          </button>
          {this.state.showGuideText &&
            <div className='guide-text'>
            Tap the blue pins to find an F+S product
            </div>
          }
          </div>
        }
        {isBrowser && !this.state.showHero &&
          <MapContainer
            style={{
              width: '100vw',
              height: '100%'
            }}
            {...mapSettings}
          >
            <TileLayer
              url="https://api.mapbox.com/styles/v1/mwieczorkowski/cla6xeovq002a14n22bnho004/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibXdpZWN6b3Jrb3dza2kiLCJhIjoiY2xhNng3NXVtMWp0eTNwbW53cmVxejJvZCJ9.wZhir1-OhSqnLAYnXZ0TjA"
            />
            <Marker
              icon={RedIcon}
              position={CENTER}>
              <Popup>
              Moscone Center <br />
              (You are here.)
              </Popup>
            </Marker>
            {this.state.markers.map((marker, i)=>{
              let pos = [marker.position.lat, marker.position.long]
              return (
                <Marker
                key={i}
                icon={BlueIcon}
                eventHandlers={{
                  click: (e) => {
                    this.showMarkerDetail(i)
                  },
                }}
                position={pos}>
                </Marker>
              )
            })}
          </MapContainer>
        }
        {this.state.markers.map((marker, i)=>{
          let show = marker.show
          return(
            <Modal
              open={show}
              key={i*100}
              handleClose={this.closeMarker}>
              <div className='content-title'>
              <h1>{marker.title}</h1>
              <h1 className='year'>, {marker.year}</h1>
              </div>
              <div>
              {marker.street1}
              </div>
              <div>
              {marker.street2}
              </div>
              {marker.images.length > 0 &&
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}>
                {marker.images.map((image,j)=>{
                  return (
                    <img src={image} key={j} />
                  )
                })}
              </Carousel>
              }
              <div
                className='content-text'
                dangerouslySetInnerHTML={{__html: marker.body}}
              />
              <a href={marker.link} target="_new" className='button'>
              Learn More
              </a>
            </Modal>
          )
        })}
      </MainComponent>
    )
  }
}

export default IndexPage

export const Head = () => <>
<title>Forms+Surfaces ASLA 2022 Map</title>
<link id="icon" rel="icon" href={favicon} />
</>

const MainComponent = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-grow: 1;
flex-direction: column;
.content-popup {
  height: 90vh;
  width: 90vw;
  position: fixed !important;
  top: 10vh !important;
  left: unset !important;
  bottom: 10vh !important;
  transform: unset !important;
}
.guide-top {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1001;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: calc(100% - 20px);
  flex-grow: 1;
}
.back-button {
  width: 2rem;
  height: 2rem;
  background: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 1rem;
    height: 1rem;
  }
}
.guide-text {
  line-height: 2rem;
  background: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0px 2rem 0;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
}
.leaflet-top.leaflet-left {
  display: none;
}
`;

const InnerPopup = styled.div`
height: 90vh;
width: 90vw;
max-height: 600px;
max-width: 350px;
position: fixed;
top: 10vh;
`;
