import React, { Component } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import navButtonImg from './images/nav-button.svg'
import titleTopImg from './images/title-top.svg'
import titleBottomImg from './images/title-bottom.svg'
import happyPlusImg from './images/happy-plus.svg'
const heroBG = './images/home-bg.jpg'

class HeroCover extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }


  render() {
    let open = this.props.open
    return(
      <>
      {open &&
        <HeroWrapper>
          <div className='hero'>
            <StaticImage src={heroBG} />
            <button onClick={this.props.toggleHero}>
            Go to Map <img src={navButtonImg} />
            </button>
            <img className='happy-plus' src={happyPlusImg} />
            <img className='title-top' src={titleTopImg} />
            <img className='title-bottom' src={titleBottomImg} />
          </div>
          <div className='text-content'>
            <p>We’re thrilled to be here in The Golden City showcasing our
            newest products. This year we’re highlighting our commitment to
            designing beautiful products that last for generations.</p>
            <p>San Fran is home to many F+S projects, so we’ve made an
            interactive map with projects within walking distance of ASLA.
            Take a look!</p>
            <p>Be sure to <a href="https://www.instagram.com/forms_surfaces/"
            target="_blank">
            follow us on Instagram</a> for the chance to participate
            in our giveaway!</p>
          </div>
        </HeroWrapper>
      }
      </>
    )
  }
}

export default HeroCover

const HeroWrapper = styled.section`
position: fixed;
top:0;
left:0;
right:0;
bottom:0;
z-index: 1002;
background: #fff;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
div.hero {
  width: 100%;
  height: 60vh;
  overflow: hidden;
  position: relative;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.3);
    }
    img {
      object-fit: cover;
      object-position: bottom right;
    }
  }
  .title-top {
    position: absolute;
    top: 1.5rem;
    left: 2rem;
  }
  .title-bottom {
    position: absolute;
    bottom: 1.5rem;
    left: 2rem;
  }
  .happy-plus {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
  }
  button {
    position: absolute;
    bottom: 1.5rem;
    right: 1rem;
    color: #fff;
    background-color: #E21C3C;
    z-index: 1;
    padding: 1rem;
    font-size: 1rem;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
div.text-content {
  height: 40%;
  padding: 1rem 2rem 2rem;
}
@media (min-width: 900px){
  div.hero {
    max-width: 900px;
    height: 80vh;
    .gatsby-image-wrapper {
      img {
        object-position: bottom center;
      }
    }
  }
  div.text-content {
    height: 20vh;
    max-width: calc(900px - 4rem);
  }
}
`
